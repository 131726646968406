































































import { Component, Mixins } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'

import ValidatorMixin from '@/modules/shared/mixins/ValidatorMixin'
import MailService from '@/modules/shared/services/MailService'
import UserContact from '@/modules/shared/entities/UserContact'
import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'

@Component({
  components: {
    PrimaryButton,
  },
  directives: {
    mask,
  },
})
export default class ContactForm extends Mixins(ValidatorMixin) {
  private model = new UserContact()

  private async submit() {
    if (!this.validate()) return

    try {
      await MailService.getInTouch(this.model)

      this.$toasted.success(this.$i18n.t('contact.mail_success') as string, { icon: 'check' })
      this.reset()
    } catch (err) {
      console.error(err)
    }
  }
}
